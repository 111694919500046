import { React, useEffect, useState } from "react";
import { variables, OperationManagementType, SerialPortStatus, UrlPath } from "../../Variables";
import SerialNumberStartWork from "./workCheck/SerialNumberStartWork";
import LotNumberStartWork from "./workCheck/LotNumberStartWork";
import { v4 as uuidv4 } from "uuid";
import { MeasureText, NavigateToOperationLogin, Validate } from "../util/commonFun";
import PageLoading from "../../components/PageLoading";
import {
    IsPortConnected,
    GetPort,
    ConnectSerialPort,
    CloseSerialPort
} from "../util/SerialPortConnection";
import { useNavigate } from "react-router";
import { appInsights } from "../util/ApplicationInsight";

const tdWidth = 300;
const tblFontSize = 22;
function OperationStartWork(props) {
    const type = sessionStorage.getItem('managementType') ? Number(sessionStorage.getItem('managementType')) : OperationManagementType.SERIALNUMBER;
    const currLotNumber = sessionStorage.getItem('lotNo') ? sessionStorage.getItem('lotNo') : "";
    const [managementType, setManagementType] = useState(type);
    const [displayTooltip, setTooltip] = useState(null);
    const [seisanInfoList, setSeisanInfoList] = useState([]);
    const [lotNumber, setLotNumber] = useState(currLotNumber);
    const [dialogBox, setDialogBox] = useState(null);
    const [connectionLabel, setConnectionLabel] = useState(IsPortConnected() ? SerialPortStatus.Connected : SerialPortStatus.Disconnected);
    const [keiKaKuSuu, setKeiKaKuSuu] = useState(0);
    let navigate = useNavigate();

    useEffect(() => {
        const validate = async () => {
            try {
                await Validate(UrlPath.Operation);
            } catch (error) {
                if (error === 401) {
                    NavigateToOperationLogin(navigate);
                }
                else {
                    console.error(error);
                    appInsights.trackException({ ...error, errorFunction: "OperationStartWork.useEffect()" });
                }
            }
        }
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (lotNumber.length === 0) {
            setSeisanInfoList([]);
        }
    }, [lotNumber])

    function handleBtnClick(type) {
        setTooltip(null);
        if (managementType === type) {
            return;
        }
        sessionStorage.setItem('managementType', type);
        setManagementType(type);
        setLotNumber("");
        setKeiKaKuSuu(0);
        setSeisanInfoList([]);
        sessionStorage.removeItem('lotNo');
        sessionStorage.removeItem('dispProcessId');
        sessionStorage.removeItem('matomesuu');
        sessionStorage.removeItem('itemCode');
    }

    function handleMouseOverToDisplayTooltip(processName, idx, e) {
        if (displayTooltip === null && MeasureText(processName, tblFontSize, tdWidth)) {
            e.stopPropagation();
            e.preventDefault();
            let positionY = e.pageY + (e.currentTarget.clientHeight / 2);
            setTooltip(
                <div id={idx} style={{ position: 'absolute', left: '1440px', top: positionY + 'px' }} onMouseLeave={() => { setTooltip(null); }}>
                    <div className={"max-w-[450px] break-all px-2 py-2 rounded-sm border-2 border-[#777777] bg-white text-[" + tblFontSize + "px]"}>
                        {processName}
                    </div>
                </div>
            );
        }
    }

    async function onClickConnection() {
        let result = await ConnectSerialPort();
        if (result === true) {
            setConnectionLabel(SerialPortStatus.Connected);
        }
        else {
            setConnectionLabel(SerialPortStatus.Disconnected);
        }
    }

    if (GetPort() !== null) {
        GetPort().addEventListener("disconnect", function (event) {
            CloseSerialPort();
            setConnectionLabel(SerialPortStatus.Disconnected);
        });
    }

    return (
        <div className="flex w-[1920px] h-[1080px] bg-[#FFFFFF]">
            <PageLoading />
            <div className="w-[420px] h-[1080px] bg-[#0073CD]">
                <div className="absolute top-[460px] left-[20px]">
                    <span className="w-[410px] pb-[10px] h-[160px] text-[#FFFFFF] text-[40px] text-center font-black">Roland DG Assemble<center className="pt-[30px] text-[32px]">for Operator</center></span>
                    <div className="w-[70px] h-[28px] mt-[40px] ml-[155px] text-[#0073CD] text-center bg-white rounded-full">{variables.VERSION}</div>
                    <div className="w-[200px] h-[82px] bg-white mt-[35px] ml-[95px]">
                        <div className="font-bold text-[18px] ml-[54px] pt-[9px]">COM接続</div>
                        <div className="mt-[8px] ml-[17px]">
                            <button
                                type="button"
                                className={"w-[166px] h-[33px] border-2 border-[#C8C6C4] " + variables.HOVER_CSS}
                                onClick={onClickConnection}>
                                {connectionLabel}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-[960px] h-[1080px] relative">
                <div className="absolute font-bold text-[26px] top-5 left-[107px]">管理方法</div>
                <div className="absolute flex h-[113px] top-12 left-[107px]">
                    <div className={"w-[365px] h-[80px] cursor-pointer text-center mr-4 mt-4 rounded-[2px]"
                        + (managementType === OperationManagementType.SERIALNUMBER ? " border-2 border-[#0073CD] bg-[white]"
                            : " bg-[#D8D7D5] border-2 hover:border-[#707070] hover:opacity-100 opacity-60")}
                        onClick={() => handleBtnClick(OperationManagementType.SERIALNUMBER)}
                        key={uuidv4()}>
                        <div className="flex relative">
                            <div className="text-left mt-3 ml-1 text-[22px] font-bold">製造番号 / シリアル番号</div>
                            <input
                                key={uuidv4()}
                                className="absolute right-1 w-[18px] h-[18px] mt-2 cursor-pointer"
                                type="radio"
                                id={"type" + OperationManagementType.SERIALNUMBER}
                                name={OperationManagementType.SERIALNUMBER}
                                checked={managementType === OperationManagementType.SERIALNUMBER}
                                readOnly={true} />
                        </div>
                        <div className=" text-left mt-1 ml-1 text-[18px]">製品個々に連続した識別番号がある場合</div>
                    </div>
                    <div className={"w-[365px] h-[80px] cursor-pointer text-center mr-4 mt-4 rounded-[2px]"
                        + (managementType === OperationManagementType.LOTNUMBER ? " border-2 border-[#0073CD] bg-[white]"
                            : " bg-[#D8D7D5] border-2 hover:border-[#707070] hover:opacity-100 opacity-60")}
                        onClick={() => handleBtnClick(OperationManagementType.LOTNUMBER)}
                        key={uuidv4()}>
                        <div className="flex relative">
                            <div className=" text-left mt-3 ml-1 text-[22px] font-bold">ロット番号</div>
                            <input
                                key={uuidv4()}
                                className="absolute right-1 w-[18px] h-[18px] mt-2 cursor-pointer"
                                type="radio"
                                id={"type" + OperationManagementType.LOTNUMBER}
                                name={OperationManagementType.LOTNUMBER}
                                checked={managementType === OperationManagementType.LOTNUMBER}
                                readOnly={true}
                            />
                        </div>
                        <div className="text-left mt-1 ml-1 text-[18px]">複数台に1つの識別番号がある場合</div>
                    </div>
                </div>
                <div className="absolute top-[158px] left-[50px] w-[836px] border-t-[3px] border-[#D8D7D5] ml-3" />
                <div className="absolute top-[175px] left-[16px]">
                    {managementType === OperationManagementType.SERIALNUMBER ?
                        <SerialNumberStartWork key={managementType} />
                        :
                        <LotNumberStartWork key={managementType} setLotNumber={setLotNumber} lotNumber={lotNumber} setKeiKaKuSuu={setKeiKaKuSuu} keiKaKuSuu={keiKaKuSuu}
                            setSeisanInfoList={setSeisanInfoList} seisanInfoList={seisanInfoList} setDialogBox={setDialogBox} />
                    }
                </div>
                <div />
            </div>
            {/* ロット番号で管理する場合、表示する部分 */}
            {(managementType === OperationManagementType.LOTNUMBER) &&
                <div className="w-[540px] h-[1080px] border-2 border-[#C8C6C4] shadow-md">
                    <div className=" font-bold text-[26px] ml-4 mt-5">ロット番号</div>
                    <div className=" font-bold text-[26px] ml-4 mt-3 break-all">{lotNumber}</div>
                    {lotNumber.length > 0 ? <div className="absolute top-[128px] right-0 mr-8 font-bold text-[26px] ">計画数 : {keiKaKuSuu}</div> : null}
                    <div className={"absolute top-[158px] text-[24px] w-[537px] h-[918px] border-t-[3px] border-[#D8D7D5]"}>
                        <div className=" flex w-[537px] h-[60px] font-bold border-b-2 border-[#D8D7D5]">
                            <div className="w-[340px] py-4"><span className="ml-10">工程</span></div>
                            <div className="w-[70px] py-4"><span className="ml-2">完了</span></div>
                            <div className="w-[120px] py-4"><span className="ml-1">仕掛かり</span></div>
                        </div>
                        <div className={"w-[537px] h-[858px] border-0 overflow-y-scroll overflow-x-hidden text-[" + tblFontSize + "px]"}>
                            <table width={510}>
                                {seisanInfoList.map((singleObj, idx) => (
                                    <tbody key={uuidv4()}>
                                        <tr className="h-[52px] border-b-2" key={uuidv4()}>
                                            <td className="w-[350px]">
                                                <div className="flex">
                                                    <div className="w-[25px] text-right ml-2 mr-2">{singleObj.dispOrder}</div>
                                                    <div className={"w-[" + tdWidth + "px] ellipse one-line overflow-hidden"}
                                                        onMouseOver={(e) => handleMouseOverToDisplayTooltip(singleObj.processName, idx, e)}
                                                        onMouseLeave={() => { setTooltip(null); }}
                                                    >{singleObj.processName}</div>
                                                </div>
                                            </td>
                                            <td className="w-[60px] font-bold ">
                                                <div className="w-[38px] text-right">{singleObj.completedCount}</div>
                                            </td>
                                            <td className="w-[100px] font-bold">
                                                <div className="w-[72px] text-right ml-2">{singleObj.inProgressCount}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            }
            {displayTooltip}
            {dialogBox}
        </div >
    );
}

export default OperationStartWork;