import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { variables, ManageItemType, CompareSource, WorkStatus, OkNgResult, BunkiStatus } from "../../Variables";
import { urlCompanyName } from "../util/commonFun";
import { appInsights } from "../util/ApplicationInsight";

const axios = require('axios');
const emptyProcessObj = {
    processId: "",
    processName: "",
    dispOrder: -1,
    dispProcessId: "",
    processChartID: "",
    notifyOfEnd: false,
    isLastProcess: false,
};

const emptyWorkObj = {
    processId: "",
    workId: "",
    workName: "",
    workNote: "",
    dispOrder: -1,
    objUrl: "",
    dispWorkId: "",
    isIrregular: false,
    bunkiStatus: BunkiStatus.None,
};

const emptyManageItemObj = {
    workId: "",
    manageItemType: -1,
    description: "",
};

const emptyDetailDocObj = {
    workId: "",
    detailDocType: 0,
    docFileName: "",
    objUrl: "",
}

const emptyToolList = {
    workId: "",
    toolId: "",
    toolFileName: "",
    displayOrder: -1,
    identifyCode: 0,
    toolName: "",
    memberCount: 0,
    objUrl: "",
};

const emptyUser = {
    userId: -1,
    userName: "",
    displayUserCode: "",
};

const emptyItem = {
    itemCode: -1,
    itemName: "",
    displayItemCode: "",
    processCnt: 0,
    isEnd: false,
    managementType: 0,
}

const emptyOperationLog = {
    dispWorkId: "",
    workStatusId: WorkStatus.NONE,
    workDispOrder: -1,
    manageResult: null,
}

const emptyTextCompareWork = {
    workId: "",
    manageResult: null,
};

const emptyCompletedManageResultObj = {
    workId: "",
    manageResult: null,
};

export const fetchSerialNumberWorkList = createAsyncThunk("operation/fetchSerialNumberWorkList",
    async param => {
        let paramobj = { serialNo: param.serialNo, serialOperationLogLockId: param.serialOperationLogLockId, itemCode: param.itemCode }
        const response = await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/get-serialnumber-work-list",
            params: paramobj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).catch((error) => {
            appInsights.trackTrace({ params: JSON.stringify(paramobj) });
            appInsights.trackException({ ...error, errorFunction: "serialOperationSlice.fetchSerialNumberWorkList()" });
            throw (error.response.status);
        });
        return response.data;
    });

export const fetchDetailDocByWorkID = createAsyncThunk("operation/fetchDetailDocByWorkID",
    async workId => {
        const response = await axios({
            method: 'GET',
            url: variables.DETAIL_DOC_URL + "/get-by-workid",
            params: { workId: workId },
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).catch((error) => {
            appInsights.trackTrace({ params: JSON.stringify({ workId: workId }) });
            appInsights.trackException({ ...error, errorFunction: "serialOperationSlice.fetchDetailDocByWorkID()" });
            throw (error.response.status);
        });
        return response.data;
    });

export const fetchToolByWorkID = createAsyncThunk("operation/fetchToolByWorkID",
    async workId => {
        const response = await axios({
            method: 'GET',
            url: variables.TOOL_URL + "/getToolListByWorkID",
            params: { workId: workId },
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).catch((error) => {
            appInsights.trackTrace({ params: JSON.stringify({ workId: workId }) });
            appInsights.trackException({ ...error, errorFunction: "serialOperationSlice.fetchToolByWorkID()" });
            throw (error.response.status);
        });
        return response.data;
    });

export const fetchSerialCompletedManageResult = createAsyncThunk("operation/fetchSerialCompletedManageResult",
    async param => {
        const response = await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/get-serial-completed-manage-result",
            params: { serialOperationLogLockId: param.serialOperationLogLockId, serialNo: param.serialNo },
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).catch((error) => {
            appInsights.trackTrace({ params: JSON.stringify({ serialOperationLogLockId: param.serialOperationLogLockId, serialNo: param.serialNo }) });
            appInsights.trackException({ ...error, errorFunction: "serialOperationSlice.fetchSerialCompletedManageResult()" });
            throw (error.response.status);
        });
        return response.data;
    });

const initState = {
    isWorkExpandMode: false,
    isPauseExpandMode: false,
    isPauseConfirmMsg: false,
    isManageItemTypeFocusable: false,
    isWorkImageExpandMode: false,
    isTeishiInput: false,
    justSelectWorkId: "",
    serialNumber: "",
    version: -1,
    serialOperationLogLockId: -1,
    lockKey: "",
    serialOperationLogId: -1,
    completedManageResultObj: { ...emptyCompletedManageResultObj },
    selectedUser: { ...emptyUser },
    selectedItem: { ...emptyItem },
    selectedWork: { ...emptyWorkObj },
    processObj: { ...emptyProcessObj },
    dataList: {
        workList: [],
        manageItemList: [],
        detailDocList: [],
        toolList: [],
        serialOperationLogList: [],
        textCompareWorkList: []
    },
};

const serialOperationSlice = createSlice({
    name: "serialOperation",
    initialState: initState,
    reducers: {
        setWorkExpandMode: (state, action) => {
            state.isWorkExpandMode = action.payload;
        },

        setPauseExpandMode: (state, action) => {
            state.isPauseExpandMode = action.payload;
        },

        setPauseConfirmMsg: (state, action) => {
            state.isPauseConfirmMsg = action.payload;
        },

        setSelectedWork: (state, action) => {
            state.selectedWork = action.payload;
        },

        setIsManageItemTypeFocusable: (state, action) => {
            state.isManageItemTypeFocusable = action.payload;
        },

        setIsWorkImageExpandMode: (state, action) => {
            state.isWorkImageExpandMode = action.payload;
        },

        setJustSelectWorkId: (state, action) => {
            state.justSelectWorkId = action.payload;
        },

        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },

        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },

        setInputSerialNumber: (state, action) => {
            state.serialNumber = action.payload;
        },

        setVersion: (state, action) => {
            state.version = action.payload;
        },

        setSerialOperationLogLockId: (state, action) => {
            state.serialOperationLogLockId = action.payload;
        },

        setLockKey: (state, action) => {
            state.lockKey = action.payload;
        },

        setShowTeishiInput: (state, action) => {
            state.isTeishiInput = action.payload;
        },

        setSerialOperationLogId: (state, action) => {
            state.serialOperationLogId = action.payload;
        },

        setWorkStatusChange: (state, action) => {
            state.isManageItemTypeFocusable = false;
            const idx = state.dataList.serialOperationLogList.findIndex((item) => item.dispWorkId.toLowerCase() === action.payload.dispWorkId.toLowerCase());
            if (idx > -1) {
                state.dataList.serialOperationLogList[idx].workStatusId = action.payload.workStatusId;
                state.dataList.serialOperationLogList[idx].manageResult = action.payload.manageResult;
            }
            else {
                state.dataList.serialOperationLogList.push({
                    ...emptyOperationLog,
                    dispWorkId: action.payload.dispWorkId,
                    workStatusId: action.payload.workStatusId,
                    workDispOrder: action.payload.workDispOrder,
                    manageResult: action.payload.manageResult,
                })
            }
        },

        setLocalNewOperationLog: (state, action) => {
            const idx = state.dataList.serialOperationLogList.findIndex((item) => item.dispWorkId.toLowerCase() === action.payload.dispWorkId.toLowerCase());
            if (idx === -1) {
                state.dataList.serialOperationLogList.push({
                    ...emptyOperationLog,
                    dispWorkId: action.payload.dispWorkId,
                    workDispOrder: action.payload.workDispOrder,
                    manageResult: action.payload.manageResult,
                })
            }
        },

        setNoneWorkStatusChange: (state, action) => {
            state.isManageItemTypeFocusable = true;
            let serialOperationLogList = [...state.dataList.serialOperationLogList];
            serialOperationLogList.sort((a, b) => a.workDispOrder - b.workDispOrder);
            let idx = serialOperationLogList.findIndex((item) => item.dispWorkId.toLowerCase() === action.payload.dispWorkId.toLowerCase());
            if (idx > -1) {
                for (let i = idx; i < serialOperationLogList.length; i++) {
                    const opLog = serialOperationLogList[i];
                    opLog.workStatusId = WorkStatus.NONE;
                    if (opLog.manageResult !== "") {
                        opLog.manageResult.txtInputText = "";
                    }
                    const workObj = state.dataList.workList.find((item) => item.dispWorkId.toLowerCase() === opLog.dispWorkId.toLowerCase());
                    const manageIdx = state.dataList.manageItemList.findIndex((item) => item.workId === workObj.workId);
                    if (manageIdx > -1) {
                        state.dataList.manageItemList[manageIdx].description.txtInputText = "";
                    }
                }
            }
        },

        setInputTextChange: (state, action) => {
            const inputTxt = action.payload.text;
            const idx = state.dataList.manageItemList.findIndex((item) => item.workId === action.payload.workId);
            if (idx > -1) {
                state.dataList.manageItemList[idx].description.txtInputText = inputTxt;
            }
        },

        resetCompletedManageResult: (state, action) => {
            state.completedManageResultObj = { ...emptyCompletedManageResultObj };
        },

        resetInitState: (state, action) => {
            state.isWorkExpandMode = false;
            state.isPauseExpandMode = false;
            state.selectedUser = { ...emptyUser };
            state.selectedItem = { ...emptyItem };
            state.selectedWork = { ...emptyWorkObj };
            state.processObj = { ...emptyProcessObj };
            state.dataList = {
                workList: [],
                manageItemList: [],
                detailDocList: [],
                toolList: [],
                serialOperationLogList: []
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDetailDocByWorkID.fulfilled, (state, action) => {
            const responseDetailDocList = action.payload;
            if (responseDetailDocList && responseDetailDocList.length > 0) {
                state.dataList.detailDocList = responseDetailDocList.map(item => {
                    return {
                        ...emptyDetailDocObj,
                        workId: item.workID,
                        detailDocType: item.detailDocType,
                        docFileName: item.docFileName,
                        objUrl: item.objUrl
                    };
                });
            }
        });
        builder.addCase(fetchDetailDocByWorkID.rejected, (state, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "serialOperationSlice.builder.addCase(fetchDetailDocByWorkID.rejected)" });
        });
        builder.addCase(fetchToolByWorkID.fulfilled, (state, action) => {
            const responseToolList = action.payload;
            if (responseToolList && responseToolList.length > 0) {
                state.dataList.toolList = responseToolList.map(tool => {
                    return {
                        ...emptyToolList,
                        toolId: tool.toolId,
                        workId: tool.workId,
                        toolFileName: tool.fileName,
                        displayOrder: tool.dispOrder,
                        identifyCode: tool.identifyCode,
                        toolName: tool.toolName,
                        memberCount: tool.memberCnt,
                        objUrl: tool.objUrl,
                    };
                });
            }
        });
        builder.addCase(fetchToolByWorkID.rejected, (state, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "serialOperationSlice.builder.addCase(fetchToolByWorkID.rejected)" });
        });
        builder.addCase(fetchSerialNumberWorkList.fulfilled, (state, action) => {
            if (state.dataList.workList.length > 0) {
                return;
            }
            const responseData = action.payload;
            const processObj = responseData.processResponse;
            const workLst = responseData.workResponseList;
            const manageItemLst = responseData.manageItemResponseList;
            const serialOperationLogList = responseData.serialOperationLogList;
            const textCompareWorkList = responseData.textCompareWorkResponseList;

            if (processObj) {
                // 現在表示する工程
                state.processObj = {
                    ...emptyProcessObj,
                    processId: processObj.processID,
                    processName: processObj.processName,
                    dispOrder: processObj.dispOrder,
                    dispProcessId: processObj.dispProcessID,
                    processChartID: processObj.processChartID,
                    notifyOfEnd: processObj.notifyOfEnd,
                    isLastProcess: processObj.isLastProcess
                };
                // 作業情報リストを設定する
                let workList = workLst.filter((item) => item.processID.toUpperCase() === processObj.processID.toUpperCase());
                workList.sort((a, b) => a.dispOrder - b.dispOrder);
                state.dataList.workList = workList.map(work => {
                    return {
                        ...emptyWorkObj,
                        processId: work.processID,
                        workId: work.workID,
                        workName: work.workName,
                        workNote: work.workNote,
                        dispOrder: work.dispOrder,
                        objUrl: work.objUrl,
                        dispWorkId: work.dispWorkID,
                        isIrregular: work.isIrregular,
                    };
                });
            }
            if (manageItemLst && manageItemLst.length > 0) {
                // 管理項目リストを設定する
                state.dataList.manageItemList = manageItemLst.map(item => {
                    return {
                        ...emptyManageItemObj,
                        workId: item.workID,
                        manageItemType: item.mngItmType,
                        description: item.data
                    };
                });
                // 作業リストの管理項目に関して分岐有り無しステータスを設定する
                const bunkiStatus = [BunkiStatus.BunkiSakiAndKanri, BunkiStatus.BunkiMoto];
                for (let i = 0; i < state.dataList.manageItemList.length; i++) {
                    let mngItem = state.dataList.manageItemList[i];
                    // 管理項目のOK/NG判定が分岐有りの場合、OKNG元先ステータスを設定する
                    if ((mngItem.manageItemType === ManageItemType.OkNgJudge && mngItem.description.okNgBranch === true && (mngItem.description.okWorkID !== "" || mngItem.description.ngWorkID !== ""))) {
                        let bunkiWorkObj = state.dataList.workList.find((x) => x.workId === mngItem.workId);
                        if (bunkiWorkObj) {
                            bunkiWorkObj.bunkiStatus = BunkiStatus.BunkiMoto;
                        }
                        if (mngItem.description.okWorkID.length > 0) {
                            bunkiWorkObj = state.dataList.workList.find((x) => x.workId === mngItem.description.okWorkID);
                            if (bunkiWorkObj) {
                                bunkiWorkObj.bunkiStatus = (bunkiWorkObj.bunkiStatus === BunkiStatus.None || bunkiWorkObj.bunkiStatus === BunkiStatus.BunkiSaki) ? BunkiStatus.BunkiSaki : BunkiStatus.BunkiSakiAndKanri;
                            }
                        }
                        if (mngItem.description.ngWorkID.length > 0) {
                            bunkiWorkObj = state.dataList.workList.find((x) => x.workId === mngItem.description.ngWorkID);
                            if (bunkiWorkObj) {
                                bunkiWorkObj.bunkiStatus = (bunkiWorkObj.bunkiStatus === BunkiStatus.None || bunkiWorkObj.bunkiStatus === BunkiStatus.BunkiSaki) ? BunkiStatus.BunkiSaki : BunkiStatus.BunkiSakiAndKanri;
                            }
                        }
                    }
                    // 管理項目の作業分岐が分岐有りの場合、作業分岐元先ステータスを設定する
                    else if (mngItem.manageItemType === ManageItemType.SagyouBunki && mngItem.description.bunkiData.some(data => data.workID !== "")) {
                        let bunkiWorkObj = state.dataList.workList.find(x => x.workId === mngItem.workId);
                        if (bunkiWorkObj) {
                            bunkiWorkObj.bunkiStatus = BunkiStatus.BunkiMoto;
                        }
                        if (mngItem.description.bunkiData.length > 0) {
                            for (let i = 0; i < mngItem.description.bunkiData.length; i++) {
                                bunkiWorkObj = state.dataList.workList.find(x => x.workId === mngItem.description.bunkiData[i].workID);
                                if (bunkiWorkObj) {
                                    bunkiWorkObj.bunkiStatus = (bunkiWorkObj.bunkiStatus === BunkiStatus.None || bunkiWorkObj.bunkiStatus === BunkiStatus.BunkiSaki) ? BunkiStatus.BunkiSaki : BunkiStatus.BunkiSakiAndKanri;
                                }
                            }
                        }
                    }
                    // 管理項目の文字比較が分岐有りの場合、文字比較元先ステータスを設定する
                    else if ((mngItem.manageItemType === ManageItemType.TextCompare && mngItem.description.txtCompSource === CompareSource.Work && mngItem.description.txtCompWorkID !== "")) {
                        let bunkiWorkObj = state.dataList.workList.find((x) => x.workId === mngItem.workId);
                        if (bunkiWorkObj && bunkiStatus.some((status) => status === bunkiWorkObj.bunkiStatus) === false) {
                            bunkiWorkObj.bunkiStatus = (bunkiWorkObj.bunkiStatus === BunkiStatus.BunkiSaki) ? BunkiStatus.BunkiSakiAndKanri : BunkiStatus.TextCompareMoto;
                        }
                        if (mngItem.description.txtCompWorkID.length > 0) {
                            bunkiWorkObj = state.dataList.workList.find((x) => x.workId === mngItem.description.txtCompWorkID);
                            if (bunkiWorkObj && (bunkiStatus.some((status) => status === bunkiWorkObj.bunkiStatus) === false)) {
                                bunkiWorkObj.bunkiStatus = (bunkiWorkObj.bunkiStatus === BunkiStatus.BunkiSaki) ? BunkiStatus.BunkiSakiAndKanri : BunkiStatus.TextCompareSakiAndKanri;
                            }
                        }
                    }
                    else {
                        let bunkiWorkObj = state.dataList.workList.find((x) => x.workId === mngItem.workId);
                        if (bunkiWorkObj && bunkiWorkObj.bunkiStatus !== BunkiStatus.TextCompareSakiAndKanri) {
                            bunkiWorkObj.bunkiStatus = (bunkiWorkObj.bunkiStatus === BunkiStatus.BunkiSaki || bunkiWorkObj.bunkiStatus === BunkiStatus.BunkiSakiAndKanri) ? BunkiStatus.BunkiSakiAndKanri
                                : BunkiStatus.Kanri;
                        }
                    }
                }
            }
            // ログ履歴によって作業ステータスを更新   
            state.dataList.serialOperationLogList = [];
            if (serialOperationLogList && serialOperationLogList.length > 0) {
                let isResetNoneStatus = false;
                const workList = state.dataList.workList;
                const maxId = serialOperationLogList && Math.max(...serialOperationLogList.map(e => e.serialOperationLogId));
                const lastOpLogObj = serialOperationLogList && serialOperationLogList.find((item) => item.serialOperationLogId === maxId);
                // [工程開始],[作業再開][作業位置変更][バッチサイズ変更][中断][作業NG][ロック解除][工程完了][作業完了][ハードウェアNG][ハードウェアOK]ステータスの作業の場合、この作業から作業再開します。
                const restartStatusList = [WorkStatus.STARTPROCESS, WorkStatus.RESUMEWORK, WorkStatus.WORKMOVE, WorkStatus.MATOMESUUCHANGE, WorkStatus.SUSPENSION, WorkStatus.NG, WorkStatus.UNLOCK, WorkStatus.FINISHPROCESS, WorkStatus.WORKCOMPLETED, WorkStatus.HARDWARENG, WorkStatus.HARDWAREOK];
                let checkCount = 0;
                let tempSerialOperationLogId = -1;
                for (let i = 0; i < workList.length; i++) {
                    const opLogObj = serialOperationLogList.find((item) => item.dispWorkId.toUpperCase() === workList[i].dispWorkId.toUpperCase());
                    if (opLogObj) {
                        checkCount++;
                        if (isResetNoneStatus === false) {
                            if ((restartStatusList.some((status) => status === opLogObj.workStatusId) && maxId === opLogObj.serialOperationLogId) // リセットステータスの場合、
                                || (maxId > opLogObj.serialOperationLogId && lastOpLogObj.workDispOrder + 1 === opLogObj.workDispOrder)) // 作業位置変更して次の作業へ移動した場合、
                            {
                                isResetNoneStatus = true;
                                state.selectedWork = workList[i];
                            }
                        }
                        const manageResult = opLogObj.manageResult.length === 0 ? "" : JSON.parse(opLogObj.manageResult);
                        if (isResetNoneStatus === true && manageResult !== "") {
                            manageResult.txtInputText = "";
                        }
                        state.dataList.serialOperationLogList.push({
                            ...emptyOperationLog,
                            dispWorkId: opLogObj.dispWorkId,
                            // 以前のIDが現在のIDより大きい場合、現在の[workStatusId]をNONEに変更する必要です。
                            workStatusId: (isResetNoneStatus || tempSerialOperationLogId > opLogObj.serialOperationLogId) ? WorkStatus.NONE : opLogObj.workStatusId,
                            workDispOrder: opLogObj.workDispOrder,
                            manageResult: manageResult,
                        });
                        // 以前のIDが現在のIDより大きい場合、以前のIDを保持
                        tempSerialOperationLogId = (opLogObj.serialOperationLogId > tempSerialOperationLogId) ? opLogObj.serialOperationLogId : tempSerialOperationLogId;
                    }
                    else if (checkCount === serialOperationLogList.length && state.selectedWork.workId === "") {
                        state.selectedWork = workList[i];
                    }
                }
                //　文字登録又は文字比較作業の「txtInputText」値があれば、管理項目の「txtInputText」更新します。
                const opLogLst = state.dataList.serialOperationLogList;
                opLogLst.forEach(log => {
                    if (log.manageResult !== "" && log.manageResult.txtInputText !== "") {
                        const workObj = workList.find((item) => item.dispWorkId.toLowerCase() === log.dispWorkId.toLowerCase());
                        const manageItm = state.dataList.manageItemList.find((item) => item.workId.toLowerCase() === workObj.workId.toLowerCase());
                        if (manageItm) {
                            manageItm.description.txtInputText = log.manageResult.okNgResult === OkNgResult.NG ? "" : log.manageResult.txtInputText;
                            manageItm.description.okNgResult = log.manageResult.okNgResult;
                        }
                    }
                });
            }
            // 管理項目の文字比較作業が別の工程の文字列登録作業に設定している場合、比較する作業リストを取る
            if (textCompareWorkList) {
                state.dataList.textCompareWorkList = textCompareWorkList.map(item => {
                    return {
                        ...emptyTextCompareWork,
                        workId: item.workId,
                        manageResult: item.manageResult.length === 0 ? "" : JSON.parse(item.manageResult),
                    };
                });
            }
        });
        builder.addCase(fetchSerialNumberWorkList.rejected, (state, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "serialOperationSlice.builder.addCase(fetchToolByWorkID.rejected)" });
        });
        builder.addCase(fetchSerialCompletedManageResult.fulfilled, (state, action) => {
            let completedManageResultObj = action.payload;
            state.completedManageResultObj = {
                ...emptyCompletedManageResultObj,
                workId: completedManageResultObj.workId,
                manageResult: completedManageResultObj.manageResult.length === 0 ? "" : JSON.parse(completedManageResultObj.manageResult),
            }
        });
        builder.addCase(fetchSerialCompletedManageResult.rejected, (state, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "serialOperationSlice.builder.addCase(fetchSerialCompletedManageResult.rejected)" });
        });
    },
});

export const {
    setWorkExpandMode,
    setPauseExpandMode,
    setPauseConfirmMsg,
    setSelectedWork,
    setShowTeishiInput,
    setWorkStatusChange,
    setNoneWorkStatusChange,
    setInputTextChange,
    setJustSelectWorkId,
    setSelectedUser,
    setSelectedItem,
    setInputSerialNumber,
    resetInitState,
    setLocalNewOperationLog,
    setIsManageItemTypeFocusable,
    setVersion,
    setSerialOperationLogLockId,
    setLockKey,
    setIsWorkImageExpandMode,
    resetCompletedManageResult,
    setSerialOperationLogId
} = serialOperationSlice.actions;
export default serialOperationSlice.reducer;